import React from 'react';
import s from './navBarChoiceQuiz.module.css'
import learnLogo from '../resource/learn_play.svg'
import product from '../resource/product.svg'
import logoNative from "./logo_novo.svg"

const NavBarChoiceQuiz = () => {
    return (


        <nav className={s.wrapper}>
            <img className={s.logo_native} src={logoNative} alt=""/>
              <div className={s.logo}>
                  <div className={s.logo_up}>интерактивная командная игра</div>
                  <div  className={s.logo_down}>РОССИЯ В ДЕТАЛЯХ</div>

              </div>
            <div className={s.merch_logo}>
                {/*<img className={s.learnLogo} src={learnLogo} alt=""/>*/}
                <div className={s.merchWord}>ИГРАЙ</div>
                <div className={s.ring}></div>
                <div className={s.merchWord}>УЗНАВАЙ</div>
                <div className={s.ring}></div>
                <div className={s.merchWord}>ПУТЕШЕСТВУЙ</div>
                {/*<img onClick={()=>{if(window.confirm('Перезагрузить страницу?'))window.location.href = window.location.origin}} className={s.product} src={product} alt=""/>*/}
            </div>
        </nav>

    );
};

export default NavBarChoiceQuiz;